<template>
  <v-container>
    <v-layout class="mt-10">
      <v-col cols="12" md="8" offset-md="2" class="bgBody">
        <v-row>
          <v-col cols="12" md="1" align="left">
            <v-btn text color="#000000" @click="$router.go(-1)">
              <v-icon>mdi-chevron-left</v-icon> Back
            </v-btn>
          </v-col>
          <v-col cols="12" md="10" align="center">
            <h4 class="font-weight-bold mb-3">
              Confirm Table Booking with {{ restaurant.name }}
            </h4>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10" offset="1" align="center">
            <span class="mb-3">Date: {{ bookingDetails.bookingDate }}</span>
            <br />
            <span class="mb-3" v-if="bookingDetails.bookingLunchTime !== ''">
              Lunch Time: {{ bookingDetails.bookingLunchTime }}
            </span>
            <span class="mb-3" v-if="bookingDetails.bookingLunchTime !== ''">
              | Dinner Time: {{ bookingDetails.bookingDinnerTime }}</span
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="mt-5">
            <v-layout wrap>
              <v-col cols="5" offset="1" md="4" offset-md="3">
                <h5>Total Guest:</h5>
              </v-col>
              <v-col cols="4" offset="1">
                <h5>{{ bookingDetails.guestCount }}</h5>
              </v-col>
            </v-layout>
            <v-layout wrap>
              <v-col cols="5" offset="1" md="4" offset-md="3">
                <h5>Total:</h5>
              </v-col>
              <v-col cols="4" offset="1">
                <h5>${{ total }}</h5>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="11" offset="1" class="mt-5">
            <v-radio-group v-model="payment" row>
              <v-radio
                color="#6D8663"
                label="Pay Part Now"
                value="partPayment"
                @click="partPayment"
              ></v-radio>
              <v-radio
                color="#6D8663"
                label="Pay in Full"
                value="fullPayment"
                @click="fullPayment"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row> -->

        <!-- <v-dialog v-model="dialogPartPayment" width="400" persistent>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">

                  <v-layout wrap>
                    <v-text-field
                      outlined
                      label="Amount ($)"
                      v-model="total"
                    ></v-text-field>
                  </v-layout>

                  <v-layout wrap>
                    <v-col cols="12" align="center">
                      <v-btn
                        depressed
                        small
                        color="#6D8663"
                        dark
                        @click="paymethod"
                        >Pay</v-btn
                      >

                      <v-btn
                        class="ml-2"
                        @click="dialogPartPayment = false"
                        small
                        color="#000000"
                        dark
                        >Cancel</v-btn
                      >
                    </v-col>
                  </v-layout>
                  <v-layout ref="paypal"></v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog> -->

        <!-- <v-dialog v-model="dialogFullPayment" width="400" persistent>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-layout wrap>
                    <v-text-field
                      outlined
                      label="Amount ($)"
                      v-model="total"
                      readonly
                    ></v-text-field>
                  </v-layout>

                  <v-layout wrap>
                    <v-col cols="12" align="center">
                      <v-btn
                        depressed
                        small
                        color="#6D8663"
                        dark
                        @click="paymethod"
                        >Pay</v-btn
                      >
                      <v-btn
                        class="ml-2"
                        @click="dialogFullPayment = false"
                        small
                        color="#000000"
                        dark
                        >Cancel</v-btn
                      >
                    </v-col>
                  </v-layout>
                  <v-layout ref="paypal"></v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog> -->

        <v-row>
          <v-col cols="12" align="center">
            <v-layout wrap>
              <v-col cols="12" align="center">
                <v-btn depressed color="#6D8663" dark @click="paymethod"
                  >Make Pay</v-btn
                >
              </v-col>
            </v-layout>
            <v-layout ref="paypal"></v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "BookingConfirmation",

  data: () => ({
    dialogFullPayment: false,
    dialogPartPayment: false,
    menuDetails: [],
    bookingDetails: {},
    payoutTotal: 0,
    total: 0,
    payment: null,
    email: "",
    restaurant: {
      name: "",
    },
  }),

  mounted() {
    this.getBooking();
    this.getRestaurant();
  },

  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },

  methods: {
    paymethod() {
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=AZE9y-RmpkGX4UpWi_pqiQMygHadDqmXeQ5u6noL8jXk4O-Bn_Mb7uOGy0RQUaCDe_myJX_MZGo2xNV7";
      script.addEventListener("load", this.setLoaded);
      document.body.appendChild(script);
    },
    setLoaded() {
      this.loaded = true;
      this.payoutTotal = this.total;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.restaurant.name,
                  amount: {
                    currency_code: "USD",
                    value: this.payoutTotal,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            console.log(order);
            this.$router.push("/booking-payment/successful");
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
    },

    getBooking() {
      const booking = localStorage.getItem("bookingDetails");
      const menus = localStorage.getItem("bookingMenuDetails");
      this.menuDetails = JSON.parse(menus);
      this.bookingDetails = JSON.parse(booking);

      this.total = this.menuDetails.menuList.reduce((total, item) => {
        return total + item.subtotal;
      }, 0);
    },

    getRestaurant() {
      const resturantData = localStorage.getItem("resturantDetails");
      this.restaurant = JSON.parse(resturantData);
    },

    fullPayment() {
      this.payoutTotal = this.total; //Converted to Naira
      this.dialogFullPayment = true;
    },

    partPayment() {
      this.payoutTotal = this.total * 550 * 100; //Converted to Naira
      this.dialogPartPayment = true;
    },

    callback: function (response) {
      if (response.status == "success") {
        this.$router.push("/booking-payment/successful");
      }
    },
    close: function () {
      console.log("Payment closed");
    },
  },
};
</script>

<style scoped>
.bgBody {
  background: #f2f6f4;
  border-radius: 10px;
}

.whiteBg {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.counter {
  font-size: 30px;
  padding-top: 15px !important;
}

.counterIcon {
  width: 62.99px;
  height: 61.42px;
  background: rgba(230, 230, 230, 0.73);
  border-radius: 10px;
}
</style>
