<template>
  <v-container>
    <v-layout>
      <v-col cols="12" md="8" offset-md="2" class="bgBody">
        <v-row>
          <v-col cols="12" md="2" align="left">
            <v-btn text color="6D8663" @click="$router.go(-1)">
              <v-icon>mdi-chevron-left</v-icon> Back
            </v-btn>
          </v-col>
          <v-col cols="12" md="10" align="center">
            <h4 class="font-weight-bold mb-3">
              {{ restaurant.name }} Menu List
            </h4>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10" offset="2" align="center">
            <span class="mb-3">Date: {{ bookingDetails.bookingDate }}</span>
            <span class="mb-3" v-if="bookingDetails.bookingLunchTime !== ''">
              | Lunch Time: {{ bookingDetails.bookingLunchTime }}
            </span>
            <span class="mb-3" v-if="bookingDetails.bookingLunchTime !== ''">
              | Dinner Time: {{ bookingDetails.bookingDinnerTime }}</span
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h6 class="subtitle-1 font-weight-bold ml-5" style="color: #000000">
              Enter Guest Details
            </h6>
            <v-layout wrap>
              <v-col cols="12" md="8" offset-md="2">
                <v-layout>
                  <v-text-field
                    style="border-radius: 15px"
                    solo
                    placeholder="Guest Name"
                    v-model="guestData.name"
                  ></v-text-field>
                </v-layout>
                <v-layout>
                  <v-text-field
                    style="border-radius: 15px"
                    solo
                    placeholder="Mobile No."
                    v-model="guestData.phone"
                  ></v-text-field>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h5 class="subtitle-1 font-weight-bold mb-3 ml-5">
              Now Select Your Menu
            </h5>
            <v-layout>
              <v-col cols="12">
                <v-slide-group show-arrows>
                  <v-slide-item v-for="(menu, n) in menuCategory" :key="n">
                    <v-btn
                      class="mx-1"
                      active="green darken-4 white--text"
                      color="#EEEEEE"
                      depressed
                      @click="toggleMenu(menu)"
                    >
                      {{ menu.name }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </v-col>
            </v-layout>
            <v-layout>
              <v-col cols="12">
                <v-layout v-if="menuStatus">
                  <v-col
                    cols="12"
                    md="10"
                    offset-md="1"
                    style="
                      background-color: #ffffff;
                      border-radius: 10px;
                      padding: 10px;
                    "
                  >
                    <v-layout
                      v-for="(menuitem, index) in menuList.menulist"
                      :key="index"
                    >
                      <v-col cols="3">
                        <span>{{ menuitem.food }}</span>
                      </v-col>
                      <v-col cols="2">
                        <span>${{ menuitem.amount }}</span>
                      </v-col>
                      <v-col cols="7">
                        <v-icon
                          color="#6D8663"
                          class="mr-1"
                          size="30"
                          @click="decreamentCounter(menuitem)"
                          >mdi-minus-circle</v-icon
                        >
                        {{ menuitem.qty }}
                        <v-icon
                          dark
                          color="#6D8663"
                          size="30"
                          class="ml-1"
                          @click="increamentCounter(menuitem)"
                          >mdi-plus-circle</v-icon
                        >

                        <span class="ml-1">${{ menuitem.subtotal }}</span>
                      </v-col>
                    </v-layout>
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" align="center">
            <v-btn depressed color="#6D8663" dark @click="updateBooking"
              >Continue</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "BookTableGuestDetails",

  data: () => ({
    picker: new Date().toISOString().substr(0, 7),
    tabs: null,
    menuStatus: false,
    menuCategory: [
      {
        id: "232j3-3434-2knhb3b2-ujhd930",
        name: "Appetizer",
        menulist: [
          {
            id: 1,
            food: "Queens Cake",
            amount: 50,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 2,
            food: "Chinese Bread",
            amount: 30,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 3,
            food: "Fruit Cake",
            amount: 25,
            qty: 0,
            subtotal: 0,
          },
        ],
      },
      {
        id: "07838-edssfe2-2323432-rre23e",
        name: "Grills",
        menulist: [
          {
            id: 1,
            food: "Grilled Chicken",
            amount: 15,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 2,
            food: "Grilled CatFish",
            amount: 10,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 3,
            food: "Grilled Suaced Beef",
            amount: 12.5,
            qty: 0,
            subtotal: 0,
          },
        ],
      },
      {
        id: "78386u-ghjd763-jhg378-72722jd",
        name: "Pizza",
        menulist: [
          {
            id: 1,
            food: "Chicken Pizza",
            amount: 18,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 2,
            food: "Beef Pizza",
            amount: 28,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 3,
            food: "Chicken Suya Pizza",
            amount: 18,
            qty: 0,
            subtotal: 0,
          },
        ],
      },
      {
        id: "ewgy3h9-3hy36-hgy3y3-783y43",
        name: "Snacks",
        menulist: [
          {
            id: 1,
            food: "Meat Pie",
            amount: 10,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 2,
            food: "Chicken Pie",
            amount: 10,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 3,
            food: "Sardine Pie",
            amount: 10,
            qty: 0,
            subtotal: 0,
          },
        ],
      },
      {
        id: "poiuoy-457yhe7-343gh3j-hjyuhw",
        name: "Parfait",
        menulist: [
          {
            id: 1,
            food: "Cake Parfait",
            amount: 12.5,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 2,
            food: "Fruit Parfait",
            amount: 10.5,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 3,
            food: "Bread Parfait",
            amount: 12.5,
            qty: 0,
            subtotal: 0,
          },
        ],
      },
      {
        id: "7h47h-jhguye-t6tyg2-673hgeu",
        name: "Drinks",
        menulist: [
          {
            id: 1,
            food: "Mixed Fruit Juice",
            amount: 20,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 2,
            food: "Water",
            amount: 25,
            qty: 0,
            subtotal: 0,
          },
          {
            id: 3,
            food: "Soft Drink",
            amount: 30,
            qty: 0,
            subtotal: 0,
          },
        ],
      },
    ],
    menuList: {},
    cartItems: [],
    bookingDetails: {},
    guestData: {
      name: "",
      phone: "",
    },
    restaurant: {},
  }),

  mounted() {
    this.getBooking();
    this.getRestaurant();
  },

  methods: {
    getBooking() {
      const booking = localStorage.getItem("bookingDetails");
      this.bookingDetails = JSON.parse(booking);
    },
    getRestaurant() {
      const resturantData = localStorage.getItem("resturantDetails");
      this.restaurant = JSON.parse(resturantData);
    },
    toggleMenu(menu) {
      this.menuStatus = true;
      this.menuList = menu;
    },

    increamentCounter(menuitem) {
      menuitem.qty = menuitem.qty + 1;
      menuitem.subtotal = menuitem.qty * menuitem.amount;

      if (this.cartItems.length <= 0) {
        this.cartItems.push(menuitem);
      } else {
        this.cartItems.forEach((element) => {
          if (menuitem.id !== element.id) {
            this.cartItems.push(menuitem);
          }
        });
      }
    },

    decreamentCounter(menuitem) {
      if (menuitem.qty <= 0) {
        menuitem.qty = 1;
      }

      menuitem.qty = menuitem.qty - 1;
      menuitem.subtotal = menuitem.qty * menuitem.amount;

      if (this.cartItems.length <= 0) {
        this.cartItems.push(menuitem);
      } else {
        this.cartItems.forEach((element) => {
          if (menuitem.id !== element.id) {
            this.cartItems.push(menuitem);
          }
        });
      }
    },

    updateBooking() {
      const bookingMenuDetails = {
        guestDetails: this.guestData,
        menuList: this.cartItems,
      };
      localStorage.setItem(
        "bookingMenuDetails",
        JSON.stringify(bookingMenuDetails)
      );
      this.$router.push("/confirm/booking");
    },
  },
};
</script>

<style scoped>
.bgBody {
  background: #f2f6f4;
  border-radius: 10px;
}

.whiteBg {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.counter {
  font-size: 30px;
  padding-top: 15px !important;
}

.counterIcon {
  width: 62.99px;
  height: 61.42px;
  background: rgba(230, 230, 230, 0.73);
  border-radius: 10px;
}
</style>
