<template>
  <v-row wrap>
    <v-col cols="12" style="padding: 0px">
      <v-layout wrap>
        <v-img src="img/ChickenGuys.jpg" height="600px" cover>
          <v-container>
            <v-col cols="12">
              <v-layout wrap>
                <v-col cols="6" md="2">
                  <v-img src="img/logo.png" height="100" contain />
                </v-col>

                <!---Hid on small screens---->
                <v-col md="9" offset-md="1" class="hidden-sm-and-down mt-1">
                  <v-row wrap>
                    <v-col cols="8">
                      <v-layout wrap>
                        <v-col cols="4">
                          <v-btn text color="#ffffff">Home</v-btn>
                        </v-col>
                        <v-col cols="4">
                          <v-btn text color="#ffffff">Restaurants</v-btn>
                        </v-col>
                        <v-col cols="3" offset="1">
                          <v-btn text color="#ffffff">Contact Us</v-btn>
                        </v-col>
                      </v-layout>
                    </v-col>

                    <v-col cols="3" offset="1" class="mt-2">
                      <v-text-field
                        solo
                        dense
                        label="Search"
                        append-icon="mdi-magnify"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <!--- Hid on large screens ---->
                <v-col cols="6" class="hidden-md-and-up mt-5" align="right">
                  <v-row wrap>
                    <v-col cols="12">
                      <v-menu open-on-hover bottom offset-y v-model="menu">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="#ffffff" small v-bind="attrs" v-on="on">
                            Menu <v-icon>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item link :to="'/'">
                            <v-list-item-title> HOME </v-list-item-title>
                          </v-list-item>
                        </v-list>

                        <v-list>
                          <v-list-item link :to="'/'">
                            <v-list-item-title> RESTAURANTS </v-list-item-title>
                          </v-list-item>
                        </v-list>

                        <v-list>
                          <v-list-item link :to="'/'">
                            <v-list-item-title> CONTACT US </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-layout>

              <v-layout wrap>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                  <h1 class="headerFontMd hidden-sm-and-down">
                    Enjoy Your Meals in any of our Restaurants.
                  </h1>

                  <h1 class="headerFontSm hidden-md-and-up">
                    Enjoy Your Meals in any of our Restaurants.
                  </h1>

                  <div class="mt-5">
                    <v-btn depressed dark color="#f7b035" rounded
                      >ORDER NOW</v-btn
                    >
                  </div>
                </v-col>
              </v-layout>
            </v-col>
          </v-container>
        </v-img>
      </v-layout>

      <ResturantList></ResturantList>
    </v-col>
  </v-row>
</template>
<script>
import ResturantList from "./others/ResturantList";
export default {
  name: "Resturants",
  components: {
    ResturantList,
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>
<style scoped>
.headerFontMd {
  color: #ffffff;
  font-size: 70px;
  font-weight: 600;
}

.headerFontSm {
  color: #ffffff;
  font-size: 35px;
  font-weight: 600;
}
</style>
