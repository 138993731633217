<template>
  <v-container fluid>
    <v-layout wrap class="mt-6">
      <v-col
        cols="12"
        md="4"
        v-for="(restaurant, index) in resturantsDetails"
        :key="index"
      >
        <v-img
          :src="restaurant.imgPath"
          height="200px"
          cover
          style="border-radius: 10px; opacity: 5"
        >
          <v-container>
            <v-col cols="12">
              <v-layout wrap>
                <v-col cols="5"></v-col>
                <v-col cols="6">
                  <h1 class="CatFontSm">{{ restaurant.name }}</h1>

                  <div class="mt-5">
                    <v-btn
                      depressed
                      dark
                      color="#f7b035"
                      rounded
                      @click="bookTable(restaurant)"
                      >Book a Table</v-btn
                    >
                  </div>
                </v-col>
              </v-layout>
            </v-col>
          </v-container>
        </v-img>
      </v-col>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      resturantsDetails: [
        {
          id: "ee762-y36ty3-23u23-2jhgh2",
          name: "Pizza Hub",
          imgPath: "img/promo_pizza.jpg",
        },
        {
          id: "67sd3-37g73-hjguy2-3232",
          name: "Chainese Restaurant",
          imgPath: "img/cafe-mozaic.png",
        },
        {
          id: "yetyo-hghg34-22dsd-2dsd32",
          name: "Queens Restaurant",
          imgPath: "img/queen_meal.jpeg",
        },
      ],
    };
  },
  methods: {
    bookTable(restaurant) {
      localStorage.setItem("resturantDetails", JSON.stringify(restaurant));
      this.$router.push("/menu-booking/" + restaurant.id);
    },
  },
};
</script>
<style scoped>
.CatFontSm {
  color: #ffffff;
  font-size: 25px;
  font-weight: 600;
}
</style>
