<template>
  <v-container>
    <v-layout class="mt-10">
      <v-col cols="12" md="8" offset-md="2" class="bgBody">
        <v-row>
          <v-col cols="12" md="1" align="left">
            <v-btn text color="#000000" @click="$router.go('/')">
              <v-icon>mdi-chevron-left</v-icon> Home
            </v-btn>
          </v-col>
          <v-col cols="12" md="10" align="center">
            <h4 class="font-weight-bold mb-3">BOOKING SUCCESSFUL</h4>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="mt-5">
            <v-layout class="mb-3">
              <v-col cols="12" align="center">
                <v-img
                  src="/img/paymentConfirmation.png"
                  alt="Payment Confirmation"
                />
              </v-col>
            </v-layout>
            <v-layout wrap>
              <v-col cols="12" align="center">
                <h2>Your booking with {{ restaurant.name }} was successful.</h2>
                <h5 align="center">
                  A payment receipt have been sent to your email.
                </h5>
                <p>
                  <v-btn depressed color="#6D8663" :to="'/'" dark
                    >Continue</v-btn
                  >
                </p>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "BookingSuccessful",

  data: () => ({
    dialogFullPayment: false,
    menuDetails: [],
    bookingDetails: {},
    total: 0,
    restaurant: {},
  }),

  mounted() {
    this.getBooking();
    this.getRestaurant();
  },

  methods: {
    getRestaurant() {
      const resturantData = localStorage.getItem("resturantDetails");
      this.restaurant = JSON.parse(resturantData);
    },
    getBooking() {
      const booking = localStorage.getItem("bookingDetails");
      const menus = localStorage.getItem("bookingMenuDetails");
      this.menuDetails = JSON.parse(menus);
      this.bookingDetails = JSON.parse(booking);

      this.total = this.menuDetails.menuList.reduce((total, item) => {
        return total + item.subtotal;
      }, 0);
    },

    close: function () {
      console.log("Payment closed");
    },
  },
};
</script>

<style scoped>
.bgBody {
  background: #f2f6f4;
  border-radius: 10px;
}

.whiteBg {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.counter {
  font-size: 30px;
  padding-top: 15px !important;
}

.counterIcon {
  width: 62.99px;
  height: 61.42px;
  background: rgba(230, 230, 230, 0.73);
  border-radius: 10px;
}
</style>
