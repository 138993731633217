import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let routes = [
  {
    path: "/",
    name: "Resturant",
    component: require("./components/Resturants.vue").default,
  },
  {
    path: "/menu-booking/:resturantId",
    name: "BookTable",
    component: require("./components/BookTable.vue").default,
  },
  {
    path: "/menu/guest-details",
    name: "BookTableGuestDetails",
    component: require("./components/BookTableGuestDetails.vue").default,
  },
  {
    path: "/confirm/booking",
    name: "BookingConfirmation",
    component: require("./components/BookingConfirmation.vue").default,
  },
  {
    path: "/booking-payment/successful",
    name: "BookingPayment",
    component: require("./components/BookingSuccessful.vue").default,
  },
];

export default new Router({
  routes,
  //   scrollBehavior(to, from, savedPosition) {
  //     return { x: 0, y: 0 };
  //   },
  //mode:'history'
});
