<template>
  <v-container>
    <v-layout>
      <v-col cols="12" md="8" offset-md="2" class="bgBody">
        <v-row>
          <v-col cols="12" align="center">
            <h4 class="font-weight-bold mb-3">
              Book a Table with {{ restaurant.name }}
            </h4>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h5
              class="subtitle-1 font-weight-bold mb-3 ml-5"
              style="color: #000000"
            >
              Select a date
            </h5>
            <v-layout>
              <v-col cols="12" class="hidden-md-and-up">
                <v-date-picker
                  color="#6D8663"
                  v-model="picker"
                  full-width
                  year-icon="mdi-calendar-blank"
                ></v-date-picker>
              </v-col>

              <v-col cols="12" class="hidden-sm-and-down">
                <v-date-picker
                  color="#6D8663"
                  v-model="picker"
                  landscape
                  full-width
                  year-icon="mdi-calendar-blank"
                ></v-date-picker>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h5 class="subtitle-1 font-weight-bold mb-3 ml-5">Select a time</h5>
            <v-layout>
              <v-col cols="12">
                <v-tabs
                  v-model="tabs"
                  fixed-tabs
                  background-color="#EEEEEE"
                  color="#6D8663"
                >
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab href="#lunch" class="text--black"> Lunch </v-tab>

                  <v-tab href="#dinner" class="text--black"> Dinner </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabs">
                  <v-tab-item value="lunch">
                    <v-card flat>
                      <v-card-text>
                        <v-layout wrap>
                          <v-col cols="12" align="center">
                            <h5>SELECT LUNCH TIME</h5>
                          </v-col>
                        </v-layout>
                        <v-layout wrap>
                          <v-col
                            cols="12"
                            align="center"
                            class="hidden-md-and-up"
                          >
                            <v-time-picker
                              format="24hr"
                              ampm-in-title
                              v-model="selectedLunchTime"
                              color="#6D8663"
                            ></v-time-picker>
                          </v-col>
                          <v-col
                            cols="12"
                            align="center"
                            class="hidden-sm-and-down"
                          >
                            <v-time-picker
                              format="24hr"
                              ampm-in-title
                              v-model="selectedLunchTime"
                              full-width
                              color="#6D8663"
                              landscape
                            ></v-time-picker>
                          </v-col>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="dinner">
                    <v-card flat>
                      <v-card-text>
                        <v-layout wrap>
                          <v-col cols="12" align="center">
                            <h5>SELECT DINNER TIME</h5>
                          </v-col>
                        </v-layout>
                        <v-layout wrap>
                          <v-col
                            cols="12"
                            align="center"
                            class="hidden-md-and-up"
                          >
                            <v-time-picker
                              format="24hr"
                              ampm-in-title
                              v-model="selectedDinnerTime"
                              color="#6D8663"
                            ></v-time-picker>
                          </v-col>
                          <v-col
                            cols="12"
                            align="center"
                            class="hidden-sm-and-down"
                          >
                            <v-time-picker
                              format="24hr"
                              ampm-in-title
                              v-model="selectedDinnerTime"
                              full-width
                              color="#6D8663"
                              landscape
                            ></v-time-picker>
                          </v-col>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h5 class="subtitle-1 font-weight-bold mb-3 ml-5">
              Expected Guests
            </h5>
            <v-layout class="mt-3">
              <v-col cols="12" class="whiteBg">
                <v-icon
                  color="#6D8663"
                  class="mr-3"
                  size="40"
                  @click="decreamentCounter(guestCounter)"
                  >mdi-minus-circle</v-icon
                >
                {{ guestCounter }}
                <v-icon
                  dark
                  color="#6D8663"
                  size="40"
                  class="ml-3"
                  @click="increamentCounter(guestCounter)"
                  >mdi-plus-circle</v-icon
                >
              </v-col>
            </v-layout>
          </v-col>
        </v-row>

        <v-row v-if="guestCounter >= 1">
          <v-col cols="12" align="center">
            <v-btn depressed color="#6D8663" dark @click="nextPage"
              >Continue</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "BookTable",

  data: () => ({
    picker: null,
    selectedLunchTime: null,
    selectedDinnerTime: null,
    tabs: null,
    guestCounter: 0,
    restaurant: {},
  }),

  created() {
    this.getRestaurant();
  },

  methods: {
    getRestaurant() {
      const resturantData = localStorage.getItem("resturantDetails");
      this.restaurant = JSON.parse(resturantData);
    },
    getTimeSeleted(time) {
      this.selectTime = time;
    },
    increamentCounter(counter) {
      this.guestCounter = counter + 1;
    },

    decreamentCounter(counter) {
      if (this.guestCounter <= 0) {
        this.guestCounter = 1;
      }
      this.guestCounter = counter - 1;
    },

    nextPage() {
      const bookingDetails = {
        bookingDate: this.picker,
        bookingLunchTime: this.selectedLunchTime,
        bookingDinnerTime: this.selectedDinnerTime,
        guestCount: this.guestCounter,
      };
      localStorage.setItem("bookingDetails", JSON.stringify(bookingDetails));
      this.$router.push("/menu/guest-details");
    },
  },
};
</script>

<style scoped>
.bgBody {
  background: #f2f6f4;
  border-radius: 10px;
}

.whiteBg {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
}

.counter {
  font-size: 30px;
  padding-top: 15px !important;
}

.counterIcon {
  width: 62.99px;
  height: 61.42px;
  background: rgba(230, 230, 230, 0.73);
  border-radius: 10px;
}
</style>
